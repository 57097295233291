import React from 'react'
import styles from './Services.module.scss'
import { motion } from "framer-motion"
import icon_1 from '../../image/Services/img_1.webp'
import icon_2 from '../../image/Services/img_2.jpg'
import icon_4 from '../../image/Services/img_4.jpg'
import icon_5 from '../../image/Services/img_5.webp'
import icon_6 from '../../image/Services/img_6.jpg'
import icon_7 from '../../image/Services/img_2.webp'
import icon_8 from '../../image/Services/img_5.jpg'
import icon_9 from '../../image/Services/img_7.jpg'
import icon_10 from '../../image/Services/img_8.jpg'
import icon_11 from '../../image/Services/img_9.jpg'
import icon_12 from '../../image/Services/img_10.jpg'
import wp from '../../image/InitialPage/touch.svg'
import { Swiper, SwiperSlide } from 'swiper/react'
import "./Services.css"
import 'swiper/scss'
import "swiper/scss/pagination"
import "swiper/scss/navigation"
import Annotation from '../Annotation/Annotation'
import SliderAdvantages from '../../components/SliderAdvantages/SliderAdvantages'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3}
    } 
}

const Services = ({isOpen}) => {

    return (
        <motion.section 
            className={styles.main} 
            id='Services'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.02, once: true}}
            >
                <div className={styles.box}>
                    <motion.h2 className={styles.title} variants={titleAnimation}>МЫ ПРЕДЛАГАЕМ <span>УСЛУГИ</span></motion.h2>
                    <motion.p className={styles.subtitle} variants={titleAnimation}>работаем как с юридическими ,так и с физическими лицами </motion.p>
                    <SliderAdvantages/>
                    <motion.ul className={styles.list}>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.01, once: true}}
                            >
                        <img className={styles.item_image}  src={icon_1} alt='илосос'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Изготовление деталей по чертежам или образцам заказчика</h3>
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.01, once: true}}
                            >
                        <img className={styles.item_image}  src={icon_2} alt='илосос'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Металлообработка</h3>
                                <p className={styles.item_text}>гибка металла, фрезерные работы, токарные работы, слесарные работы, сверление металла, шлифовка металла, нарезка резьбы</p>
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.01, once: true}}
                            >
                        <img className={styles.item_image}  src={icon_7} alt='илосос'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Лазерная резка металла</h3>
                                <p className={styles.item_text}>нержавейки, алюминия, оцинкованной стали, углеродистой стали, электротехнической стали</p>
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.01, once: true}}
                            >
                        <img className={styles.item_image}  src={icon_12} alt='илосос'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Лазерная гравировка</h3>
                                <p className={styles.item_text}>металла, дерева, пластика</p>
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.01, once: true}}
                            >
                        <img className={styles.item_image}  src={icon_8} alt='илосос'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Сварочные работы</h3>
                                <p className={styles.item_text}>лазерная сварка, ручная дуговая сварка (ММА), аргоно-дуговая сварка (TIG), полуавтоматическая сварка</p>
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.01, once: true}}
                            >
                        <img className={styles.item_image}  src={icon_9} alt='илосос'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Электроэрозионная обработка металла</h3>
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.01, once: true}}
                            >
                        <img className={styles.item_image}  src={icon_4} alt='илосос'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>3D-печать</h3>
                                <p className={styles.item_text}>фотополимером, ABS пластиком, PLA пластиком, печать больших размеров</p>
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.01, once: true}}
                            >
                        <img className={styles.item_image}  src={icon_5} alt='илосос'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>3D-моделирование</h3>
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.01, once: true}}
                            >
                        <img className={styles.item_image}  src={icon_10} alt='илосос'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Быстрое изготовление прототипов</h3>
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.01, once: true}}
                            >
                        <img className={styles.item_image}  src={icon_11} alt='илосос'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Литье пластмасс</h3>
                                <p className={styles.item_text}>изготовление мастер моделей, мелкосерийное литье в силиконовые формы</p>
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.01, once: true}}
                            >
                        <img className={styles.item_image}  src={icon_6} alt='илосос'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Разработка конструкторской документации</h3>
                            </div>
                        </motion.li>
                    </motion.ul>
                    <motion.button className={styles.button} type='button' onClick={isOpen} variants={cardAnimation}>получить консультацию <span className={styles.glare}></span> <img src={wp} alt="Whatsapp"/></motion.button>
                </div>
                <div className={styles.box_min}>
                <motion.h2 className={styles.title} variants={titleAnimation}>МЫ ПРЕДЛАГАЕМ <span>УСЛУГИ</span></motion.h2>
                <motion.p className={styles.subtitle} variants={titleAnimation}>работаем как с юридическими ,так и с физическими лицами </motion.p>
                <SliderAdvantages/>
                <Annotation/>    
                <Swiper
                                spaceBetween={10}
                                slidesPerView={1.3}
                                centeredSlides={true}
                                loop={true}
                                breakpoints={{

                                    1400: {
                                        slidesPerView: 1.5,
                                        spaceBetween: 5,
                                    },

                                    768: {
                                        slidesPerView: 1.2,
                                        spaceBetween: 5,
                                    },

                                    425: {
                                        slidesPerView: 1,
                                        spaceBetween: 5,
                                    },

                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 5,
                                    },
                                }}
                                className="mySwiper"
                                >
                            <SwiperSlide>
                                <div className={styles.item}>
                                <img className={styles.item_image}  src={icon_1} alt='илосос'/>
                                    <div className={styles.box_info}>
                                        <h3 className={styles.item_title}>Изготовление деталей по чертежам или образцам заказчика</h3>
                                    </div>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.item}>
                                <img className={styles.item_image}  src={icon_2} alt='илосос'/>
                                    <div className={styles.box_info}>
                                        <h3 className={styles.item_title}>Металлообработка</h3>
                                        <p className={styles.item_text}>гибка металла, фрезерные работы, токарные работы, слесарные работы, сверление металла, шлифовка металла, нарезка резьбы</p>
                                    </div>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.item}>
                                    <img className={styles.item_image}  src={icon_7} alt='илосос'/>
                                    <div className={styles.box_info}>
                                        <h3 className={styles.item_title}>Лазерная резка металла</h3>
                                        <p className={styles.item_text}>нержавейки, алюминия, оцинкованной стали, углеродистой стали, электротехнической стали</p>
                                    </div>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.item}>
                                    <img className={styles.item_image}  src={icon_12} alt='илосос'/>
                                    <div className={styles.box_info}>
                                        <h3 className={styles.item_title}>Лазерная гравировка</h3>
                                        <p className={styles.item_text}>металла, дерева, пластика</p>
                                    </div>
                                </div>
                            </SwiperSlide>           
                            <SwiperSlide>
                                <div className={styles.item}>
                                    <img className={styles.item_image}  src={icon_8} alt='илосос'/>
                                    <div className={styles.box_info}>
                                        <h3 className={styles.item_title}>Сварочные работы</h3>
                                        <p className={styles.item_text}>лазерная сварка, ручная дуговая сварка (ММА), аргоно-дуговая сварка (TIG), полуавтоматическая сварка</p>
                                    </div>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.item}>
                                    <img className={styles.item_image}  src={icon_9} alt='илосос'/>
                                    <div className={styles.box_info}>
                                        <h3 className={styles.item_title}>Электроэрозионная обработка металла</h3>
                                    </div>
                            </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.item}>
                                    <img className={styles.item_image}  src={icon_4} alt='илосос'/>
                                    <div className={styles.box_info}>
                                        <h3 className={styles.item_title}>3D-печать</h3>
                                        <p className={styles.item_text}>фотополимером, ABS пластиком, PLA пластиком, печать больших размеров</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.item}>
                                <img className={styles.item_image}  src={icon_5} alt='илосос'/>
                                    <div className={styles.box_info}>
                                        <h3 className={styles.item_title}>3D-моделирование</h3>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.item}>
                                    <img className={styles.item_image}  src={icon_10} alt='илосос'/>
                                    <div className={styles.box_info}>
                                        <h3 className={styles.item_title}>Быстрое изготовление прототипов</h3>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.item}>
                                    <img className={styles.item_image}  src={icon_11} alt='илосос'/>
                                    <div className={styles.box_info}>
                                        <h3 className={styles.item_title}>Литье пластмасс</h3>
                                        <p className={styles.item_text}>изготовление мастер моделей, мелкосерийное литье в силиконовые формы</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className={styles.item}>
                                    <img className={styles.item_image}  src={icon_6} alt='илосос'/>
                                    <div className={styles.box_info}>
                                        <h3 className={styles.item_title}>Разработка конструкторской документации</h3>
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                        <motion.button className={styles.button} type='button' onClick={isOpen} variants={cardAnimation}>получить консультацию <span className={styles.glare}></span> <img src={wp} alt="Whatsapp"/></motion.button>
                </div>
        </motion.section>
        
    )
}

export default Services