import React, {useState} from 'react'
import styles from './AboutMe.module.scss'
import { motion } from "framer-motion"
import play from '../../image/VideoСollection/play.svg'
import poster from '../../image/AboutMe/img_6.jpg'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: { 
        scale: 1,
        opacity: 1,
        transition: { duration: .7 }
    }
}

const textAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const AboutMe = () => {

    const [visible, setVisible] = useState(false);

    const toggleVisible = (elt) => {
        
        setVisible(true)
    }

    const makeMuted = (elt) => {
        if (elt && visible) {
            elt.play();
        }
        };

    



    return (
        <motion.section 
        className={styles.main}
        id='AboutUs' 
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.05, once: true}}
        >
            <div className={styles.container}>
                <motion.div className={`${visible ? styles.item_none : styles.item}`}  variants={imageAnimation}>
                    <button className={`${visible ? styles.play_none : styles.play}`} type='button' onClick={toggleVisible}>
                            <div className={styles.play_icon}>
                                <span className={styles.pulse}></span>
                                <span className={styles.pulse_two}></span>
                                <img className={styles.image} src={play} alt = 'плей'/>
                            </div>
                            видео презентация</button>
                    <video ref={makeMuted} controls playsInline poster={poster} className={`${visible ? styles.video : styles.video_none}`}>
                    <source src={require('./malkoff_1.mp4')} type="video/mp4"/>
                    Your browser does not support the video tag.
                </video>
                </motion.div>
                <div className={styles.info}>
                    <motion.h2 
                        className={styles.title} 
                        variants={textAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        
                        >О <span>НАС</span></motion.h2>
                    <motion.p 
                        className={styles.text} 
                        variants={textAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.001, once: true}}
                        ><span>Осуществляем широкий перечень работ,</span> связанный с <span>производством различных деталей и изделий из металла и пластмасс,</span> включая изготовление деталей по чертежам или образцам заказчика, моделирование и изготовление прототипов.</motion.p>
                    <motion.p 
                        className={styles.text} 
                        variants={textAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.001, once: true}}
                        >Мы осуществляем выполнение заказов на <span>штучное и мелкосерийное производство</span> для нужд небольших компаний, стартапов и физических лиц. <span>Более 5 лет опыта</span> в данной области позволяют нам эффективно справляться с задачами различной сложности.</motion.p>
                    <motion.p 
                        className={styles.text} 
                        variants={textAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.001, once: true}}
                        >Благодаря большому опыту в выполнении заказов различной сложности, наши <span>квалифицированные специалисты</span> подбирают наиболее <span>оптимальный процесс, материал и оборудование</span> для эффективного решения каждой конкретной задачи.</motion.p>
                        <motion.p 
                        className={styles.text} 
                        variants={textAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.001, once: true}}
                        >Работаем с небольшими сериями и штучными экземплярами, что является <span>экономичным и практичным</span> способом для прощупывания рынка и безболезненного внесения корректив в продукцию, без необходимости закупки собственного оборудования или заказа дорогостоящих пресс-форм.</motion.p>        
                </div>
            </div>   
    </motion.section>
    )
}

export default AboutMe