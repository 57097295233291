import React from 'react'
import styles from './Offers.module.scss'



const Offers = () => {
    return (
        <ul className={styles.list}>
            <li className={styles.item}>
                <p className={styles.check}>&#10003;</p>
                <p className={styles.item_text}>Производим изделия <span>любой сложности</span></p>
            </li>
            <li className={styles.item}>
                <p className={styles.check}>&#10003;</p>
                <p className={styles.item_text}>Выполняем работы <span>точно в срок</span></p>
            </li>
            <li className={styles.item}>
                <p className={styles.check}>&#10003;</p>
                <p className={styles.item_text}>Используем самое <span>современное оборудование</span></p>
            </li>
        </ul>
    )
}

export default Offers