import React, {useCallback} from 'react'
import { motion } from "framer-motion"
import styles from './CardImage.module.scss'

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const CardImage = ({item, isImageOpen}) => {

    const cardClick = useCallback(() => {
        isImageOpen(item.arr)
    }, [isImageOpen, item])

    return (
        <motion.li 
            className={styles.item}  
            onClick={cardClick}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.05, once: true}}
            variants={cardAnimation} 
            >
            <img className={styles.image} src={item.image} alt={item.title}/>
            <span className={styles.icon}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.43601 18.4791H2.57967L6.86393 14.2003L5.7916 13.1204L1.51745 17.3891V15.5539H0V20H4.43601V18.4791Z" fill="white"></path>
                        <path d="M15.5639 18.4791V20H19.9999V15.5539H18.4825V17.3891L14.2083 13.1204L13.136 14.2003L17.4203 18.4791H15.5639Z" fill="white"></path>
                        <path d="M1.51745 2.6109L5.7916 6.87959L6.86393 5.79975L2.57967 1.52091H4.43601V0H0V4.44613H1.51745V2.6109Z" fill="white"></path>
                        <path d="M14.2083 6.87452L18.4825 2.60583V4.44106H19.9999V0H15.5639V1.52091H17.4203L13.136 5.79975L14.2083 6.87452Z" fill="white"></path>
                    </svg>
                </span>
        </motion.li>
    )
}

export default CardImage