import React from 'react'
import styles from './Advantages.module.scss'
import { motion } from "framer-motion"

const titleAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .4}
    } 
}


const Advantages = ({isOpen}) => {

    return (
        <motion.section 
        className={styles.main}
        id='Advantages' 
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.05, once: true}}
        >
        <div className={styles.container}>
        <motion.h2 className={styles.title} variants={titleAnimation}>ПОЧЕМУ <span>ВЫБИРАЮТ НАС?</span></motion.h2>
        <motion.ul className={styles.list}>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_line}>
                            <div className={styles.item_box} >
                                <p className={styles.item_number}>01</p>
                                <p className={styles.item_title}>Индивидуальный подход</p>
                            </div>
                            <p className={styles.item_text}> Каждый заказ уникален, и подход, который подходит для одного проекта, может быть неэффективным для другого. Индивидуальный подход позволяет учитывать особенности каждого заказа, включая требования к <span>материалам, геометрии, термической обработке</span> и другим параметрам.</p>
                        </div>    
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_line}>
                            <div className={styles.item_box} >
                                <p className={styles.item_number}>02</p>
                                <p className={styles.item_title}>Гарантия качества</p>
                            </div>
                            <p className={styles.item_text}>Мы контролируем качество на каждом этапе производства. С учетом специфики каждого заказа применяем более строгие <span>стандарты и требования,</span> что в конечном итоге приводит к повышению качества выполненных работ, <span>надежности и долговечности </span> изготавливаемых деталей. <span> В случае обнаружения брака в течении двух недель делаем замену.</span></p>
                        </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_line}>
                            <div className={styles.item_box} >
                                <p className={styles.item_number}>03</p>
                                <p className={styles.item_title}>Высокая скорость работы</p>
                            </div>
                            <p className={styles.item_text}>Стандарт работы нашей компании исключает откладывание заявок на вторую очередь. Если наша компания взяла в работу вашу заявку или заказ, мы сразу начинаем по ней работать. <span>Время изготовления - от одной до двух недель.</span></p>
                        </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_line}>
                            <div className={styles.item_box} >
                                <p className={styles.item_number}>04</p>
                                <p className={styles.item_title}>Современное оборудование</p>
                            </div>
                            <p className={styles.item_text}>Мы используем высокотехнологичное оборудование, позволяющее даже самые мелкие детали воспроизводиться с <span>высокой точностью,</span> а также идеально сохранять все <span>размерные и геометрические параметры</span> изделия.</p>
                        </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_line}>
                            <div className={styles.item_box} >
                                <p className={styles.item_number}>05</p>
                                <p className={styles.item_title}>Квалифицированный персонал</p>
                            </div>
                            <p className={styles.item_text}>Заказы наших клиентов выполняют только самые высококвалифицированные специалисты, обладающие многолетним опытом, что положительно отражается на качестве и скорости выполненных работ.</p>
                        </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_line}>
                            <div className={styles.item_box} >
                                <p className={styles.item_number}>06</p>
                                <p className={styles.item_title}>Отношение к клиентам</p>
                            </div>
                            <p className={styles.item_text}>Мы одинаково хорошо относимся как к крупным заказчикам, так и к частным лицам, которые размещают у нас небольшие заказы. Наш приоритет – долгосрочное сотрудничество с клиентами, поэтому на все виды услуг у нас найдутся гибкие <span>предложения.</span></p>
                        </div>
                    </motion.li>
                </motion.ul>
        </div>   
    </motion.section>
    )
}

export default Advantages