import React, {useState} from 'react'
import header from './Header.module.scss'
import logo from '../../image/Header/logo.svg'
import logo_min from '../../image/Header/logo.png'
import HeaderContact from '../HeaderContact/HeaderContact'

const Header = ({isOpen, isOpenMenu}) => {

    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 10){
            setVisible(true)
        } 
        else if (scrolled <= 10){
            setVisible(false)
        }
    }

    window.addEventListener('scroll', toggleVisible);

    return (
        <header className={`${header.header} ${visible && header.header_scroll}`}>
        <div className={header.box}>
                <div className={header.info}>
                    <div className={header.box_logo}>
                        <a className={header.logo_link} href='initial'>
                            <img className={header.logo} src={logo} alt = 'логотип'/>
                            <img className={header.logo_min} src={logo_min} alt = 'логотип'/>
                        </a>
                        <HeaderContact
                            isOpen={isOpen}
                        />
                        <button className={header.button} type='button' onClick={isOpenMenu} aria-label='Открыть меню'/>
                    </div>
                    <div className={header.box_nav}>
                        <nav className={header.nav}>
                            <a className={header.link} href='#AboutUs'>О нас</a>
                            <a className={header.link} href='#Services'>Наши услуги</a>
                            <a className={header.link} href='#Advantages'>Преимущества</a>
                            <a className={header.link} href='#Photo'>Портфолио</a>
                            <a className={header.link} href='#Voprosy'>Вопросы</a> 
                            <a className={header.link} href='#Otzyvy'>Отзывы</a>
                        </nav>
                    </div>
                    
                </div>
        </div>
    </header>    
    );
};

export default Header