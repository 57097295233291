
import img_1 from '../image/Portfolio/project_1/img-1.webp'
import img_1_1 from '../image/Portfolio/project_1/img-2.webp'
import img_1_2 from '../image/Portfolio/project_1/img-3.webp'
import img_1_3 from '../image/Portfolio/project_1/img-4.webp'
import img_1_4 from '../image/Portfolio/project_1/img-5.webp'
import img_1_5 from '../image/Portfolio/project_1/img-6.webp'
import img_1_6 from '../image/Portfolio/project_1/img-7.webp'

import img_2 from '../image/Portfolio/project_2/img-1.webp'
import img_2_1 from '../image/Portfolio/project_2/img-2.webp'
import img_2_2 from '../image/Portfolio/project_2/img-3.webp'
import img_2_3 from '../image/Portfolio/project_2/img-4.webp'
import img_2_4 from '../image/Portfolio/project_2/img-5.webp'
import img_2_5 from '../image/Portfolio/project_2/img-6.webp'
import img_2_6 from '../image/Portfolio/project_2/img-7.webp'

import img_3 from '../image/Portfolio/project_3/img-1.webp'
import img_3_1 from '../image/Portfolio/project_3/img-2.webp'
import img_3_2 from '../image/Portfolio/project_3/img-3.webp'
import img_3_3 from '../image/Portfolio/project_3/img-4.webp'
import img_3_4 from '../image/Portfolio/project_3/img-5.webp'
import img_3_5 from '../image/Portfolio/project_3/img-6.webp'
import img_3_6 from '../image/Portfolio/project_3/img-7.webp'
import img_3_7 from '../image/Portfolio/project_3/img-8.webp'
import img_3_8 from '../image/Portfolio/project_3/img-9.webp'

import img_4 from '../image/Portfolio/project_4/img-1.webp'
import img_4_1 from '../image/Portfolio/project_4/img-2.webp'
import img_4_2 from '../image/Portfolio/project_4/img-3.webp'
import img_4_3 from '../image/Portfolio/project_4/img-4.webp'
import img_4_4 from '../image/Portfolio/project_4/img-5.webp'


import img_5 from '../image/Portfolio/project_5/img-1.webp'
import img_5_1 from '../image/Portfolio/project_5/img-2.webp'
import img_5_2 from '../image/Portfolio/project_5/img-3.webp'
import img_5_3 from '../image/Portfolio/project_5/img-4.webp'
import img_5_4 from '../image/Portfolio/project_5/img-5.webp'
import img_5_5 from '../image/Portfolio/project_5/img-6.webp'
import img_5_6 from '../image/Portfolio/project_5/img-7.webp'
import img_5_7 from '../image/Portfolio/project_5/img-8.webp'

import img_6 from '../image/Portfolio/project_6/img-1.webp'
import img_6_1 from '../image/Portfolio/project_6/img-2.webp'
import img_6_2 from '../image/Portfolio/project_6/img-3.webp'
import img_6_3 from '../image/Portfolio/project_6/img-4.webp'
import img_6_4 from '../image/Portfolio/project_6/img-5.webp'
import img_6_5 from '../image/Portfolio/project_6/img-6.webp'
import img_6_6 from '../image/Portfolio/project_6/img-7.webp'
import img_6_7 from '../image/Portfolio/project_6/img-8.webp'
import img_6_8 from '../image/Portfolio/project_6/img-9.webp'
import img_6_9 from '../image/Portfolio/project_6/img-10.webp'
import img_6_10 from '../image/Portfolio/project_6/img-11.webp'

import img_7 from '../image/Portfolio/project_7/img-1.jpg'
import img_7_1 from '../image/Portfolio/project_7/img-2.jpg'
import img_7_2 from '../image/Portfolio/project_7/img-3.jpg'
import img_7_3 from '../image/Portfolio/project_7/img-4.jpg'
import img_7_4 from '../image/Portfolio/project_7/img-5.jpg'
import img_7_5 from '../image/Portfolio/project_7/img-6.jpg'
import img_7_6 from '../image/Portfolio/project_7/img-7.jpg'
import img_7_7 from '../image/Portfolio/project_7/img-8.jpg'
import img_7_8 from '../image/Portfolio/project_7/img-9.jpg'

import img_8 from '../image/Portfolio/project_8/img-1.jpg'
import img_8_1 from '../image/Portfolio/project_8/img-2.jpg'
import img_8_2 from '../image/Portfolio/project_8/img-3.jpg'
import img_8_3 from '../image/Portfolio/project_8/img-4.jpg'
import img_8_4 from '../image/Portfolio/project_8/img-5.jpg'
import img_8_5 from '../image/Portfolio/project_8/img-6.jpg'
import img_8_6 from '../image/Portfolio/project_8/img-7.jpg'
import img_8_7 from '../image/Portfolio/project_8/img-8.jpg'
import img_8_8 from '../image/Portfolio/project_8/img-9.jpg'
import img_8_9 from '../image/Portfolio/project_8/img-10.jpg'
import img_8_10 from '../image/Portfolio/project_8/img-11.jpg'


export const arrProject_1 = [{image: img_1, title: 'Прибор'}, {image: img_1_1, title: 'Прибор'}, {image: img_1_2, title: 'Прибор'}, {image: img_1_3, title: 'Прибор'}, {image: img_1_4, title: 'Прибор'}, {image: img_1_5, title: 'Прибор'}, {image: img_1_6, title: 'Прибор'},];

export const arrProject_2 = [{image: img_2, title: 'Прибор'}, {image: img_2_1, title: 'Прибор'}, {image: img_2_2, title: 'Прибор'}, {image: img_2_3, title: 'Прибор'}, {image: img_2_4, title: 'Прибор'}, {image: img_2_5, title: 'Прибор'}, {image: img_2_6, title: 'Прибор'},];

export const arrProject_3 = [{image: img_3, title: 'Прибор'}, {image: img_3_1, title: 'Прибор'}, {image: img_3_2, title: 'Прибор'}, {image: img_3_3, title: 'Прибор'}, {image: img_3_4, title: 'Прибор'}, {image: img_3_5, title: 'Прибор'}, {image: img_3_6, title: 'Прибор'}, {image: img_3_7, title: 'Прибор'}, {image: img_3_8, title: 'Прибор'},];

export const arrProject_4 = [{image: img_4, title: 'Прибор'}, {image: img_4_1, title: 'Прибор'}, {image: img_4_2, title: 'Прибор'}, {image: img_4_3, title: 'Прибор'}, {image: img_4_4, title: 'Прибор'},];

export const arrProject_5 = [{image: img_5, title: 'Прибор'}, {image: img_5_1, title: 'Прибор'}, {image: img_5_2, title: 'Прибор'}, {image: img_5_3, title: 'Прибор'}, {image: img_5_4, title: 'Прибор'}, {image: img_5_5, title: 'Прибор'}, {image: img_5_6, title: 'Прибор'}, {image: img_5_7, title: 'Прибор'},];

export const arrProject_6 = [{image: img_6, title: 'Прибор'}, {image: img_6_1, title: 'Прибор'}, {image: img_6_2, title: 'Прибор'}, {image: img_6_3, title: 'Прибор'}, {image: img_6_4, title: 'Прибор'}, {image: img_6_5, title: 'Прибор'}, {image: img_6_6, title: 'Прибор'}, {image: img_6_7, title: 'Прибор'},  {image: img_6_8, title: 'Прибор'}, {image: img_6_9, title: 'Прибор'},  {image: img_6_10, title: 'Прибор'},];

export const arrProject_7 = [{image: img_7, title: 'Прибор'}, {image: img_7_1, title: 'Прибор'}, {image: img_7_2, title: 'Прибор'}, {image: img_7_3, title: 'Прибор'}, {image: img_7_4, title: 'Прибор'}, {image: img_7_5, title: 'Прибор'}, {image: img_7_6, title: 'Прибор'}, {image: img_7_7, title: 'Прибор'},  {image: img_7_8, title: 'Прибор'},];

export const arrProject_8 = [{image: img_8, title: 'Прибор'}, {image: img_8_1, title: 'Прибор'}, {image: img_8_2, title: 'Прибор'}, {image: img_8_3, title: 'Прибор'}, {image: img_8_4, title: 'Прибор'}, {image: img_8_5, title: 'Прибор'}, {image: img_8_6, title: 'Прибор'}, {image: img_8_7, title: 'Прибор'},  {image: img_8_8, title: 'Прибор'}, {image: img_8_9, title: 'Прибор'}, {image: img_8_10, title: 'Прибор'},];

export const arrPortfolio = [{image: img_1, title: 'Прибор', arr: arrProject_1}, {image: img_2, title: 'Прибор', arr: arrProject_2}, {image: img_3, title: 'Прибор', arr: arrProject_3}, {image: img_4, title: 'Прибор', arr: arrProject_4}, {image: img_5, title: 'Прибор', arr: arrProject_5}, {image: img_6, title: 'Прибор', arr: arrProject_6}, {image: img_7, title: 'Прибор', arr: arrProject_7}, {image: img_8, title: 'Прибор', arr: arrProject_8},];

export const arrAdvantages = [{text:'Делаете новый заказ '}, {text:'Доставляем его до нашего производства '}, {text:'Выполняем его в кратчайшие сроки'}, {text:'Отдаем сделанную работу доставкой '}, {text:'Радуетесь качеству '},];
