import React from 'react'
import styles from './Main.module.scss'
import InitialPage from '../../components/initialPage/InitialPage'
import AboutMe from '../../components/AboutMe/AboutMe'
import Advantages from '../../components/Advantages/Advantages'
import Feedback from '../../components/Feedback/Feedback'
import Services from '../../components/Services/Services'
import Portfolio from '../../components/Portfolio/Portfolio'
import Questions from '../../components/Questions/Questions'

const Main = ({isOpen, popupOpen, isImageOpen}) => {

    return (
        <section className={styles.main}>
            <InitialPage
                isOpen={isOpen}
            />
            <AboutMe/>
            <Services
                isOpen={isOpen}
            />
            <Advantages
                isOpen={isOpen}
            />
            <Portfolio
                isImageOpen={isImageOpen}
            />
            <Questions/>
            <Feedback/>
        </section>
    )
}

export default Main