import React, {useCallback, useState} from 'react'
import app from './App.module.scss'
import Main from '../../pages/Main/Main'
import Header  from '../Header/Header'
import Footer  from '../Footer/Footer'
import Modal from '../Modal/Modal'
import ModalForm  from '../ModalForm/ModalForm'
import Button from '../Button/Button'
import { ModalImage } from '../ModalImage/ModalImage'
import UpButton from '../UpButton/UpButton'
import MenuMobile from '../MenuMobile/MenuMobile'

function App() {

  const [isPopupFormOpen, setIsPopupFormOpen] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isPopupImageOpen, setIsPopupImageOpen] = useState(false)
  const [isArr, setIsArr] = useState(null)
  const [isMenuMobile, setIsMenuMobile] = useState(false)

  const vh = window.innerHeight / 100;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  const handleCloseMenuMobile = useCallback(() => {
    setIsMenuMobile(false)
  }, []);

  const handleOpenMenuMobile = useCallback(() => {
    setIsMenuMobile(true)
  }, []);

  const handleFormOpen = useCallback(() => {
    setIsPopupFormOpen(true)
  }, []);

  const handleFormClose = useCallback(() => {
    setIsPopupFormOpen(false)
  }, []);

  const handleOpen = useCallback(() => {
    setIsPopupOpen(true)
  }, []);

  const handleClose = useCallback(() => {
    setIsPopupOpen(false)
  }, []);

  const handleImageClose = useCallback(() => {
    setIsPopupImageOpen(false)
  }, []);

  const handleImageOpen = useCallback((arr) => {
      setIsPopupImageOpen(true)
      setIsArr(arr)
  }, [])



  return (
    <div className={app.page}>
      <Header
        isOpen={handleFormOpen}
        isOpenMenu={handleOpenMenuMobile}
      />
      <Main
        isOpen={handleFormOpen}
        popupOpen={handleOpen}
        isImageOpen={handleImageOpen}
      />
      <Footer/>
      <UpButton/>
      <Button/>
      {isPopupFormOpen && <ModalForm
        onClose={handleFormClose}
        popupOpen={handleOpen}
      ></ModalForm>}
      {isPopupOpen && 
      <Modal
        onClose={handleClose}
      />}
      {isPopupImageOpen && 
      <ModalImage
        onClose={handleImageClose}
        arr={isArr}
      />}
      <MenuMobile
        onClose={handleCloseMenuMobile}
        isMenuMobile={isMenuMobile}
      />
    </div>
  );
}

export default App;
