import React from 'react'
import { motion } from "framer-motion"
import styles from './Feedback.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import {  Pagination, Autoplay} from "swiper"
import "./Feedback.css"
import 'swiper/scss'
import "swiper/scss/pagination"
import "swiper/scss/navigation"
import img from '../../image/Feedback/img.webp'
import Annotation from '../Annotation/Annotation'

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: 1}
    } 
}


const Feedback = () => {

    return (
        <motion.section 
            className={styles.main}
            id='Otzyvy'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <motion.div className={styles.box} variants={boxAnimation}>
                <h2 className={styles.title}>ОТЗЫВЫ <span>НАШИХ КЛИЕНТОВ</span></h2>
                <Annotation/>
                    <div className={styles.slider}>
                        <Swiper
                                spaceBetween={10}
                                slidesPerView={1.3}
                                centeredSlides={true}
                                loop={true}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }}
                                pagination={{
                                    type: 'fraction'
                                }}
                                breakpoints={{

                                    1400: {
                                        slidesPerView: 1.5,
                                        spaceBetween: 5,
                                    },

                                    768: {
                                        slidesPerView: 1.2,
                                        spaceBetween: 5,
                                    },

                                    425: {
                                        slidesPerView: 1,
                                        spaceBetween: 5,
                                    },

                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 5,
                                    },
                                }}
                                modules={[Pagination, Autoplay]}
                                className="mySwiper"
                                >
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Валентин Строков</p>
                                        <p className={styles.comment}>29.09.2024</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Наш автосервис на постоянной основе пользуется услугами данного предприятия. Помогают по всем своим направлениям. Все делают качественно, размеры, все соблюдают. Спасибо, что вы есть, все ради наших клиентов :)</p>
                                    </div>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Анастасия</p>
                                        <p className={styles.comment}>13.02.2024</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Работают очень грамотные и ответственные технологи. Нужна была 3 д печать, но мало было представления как реализовать задуманное. Чтобы это функционировало)) На первой консультации погрузились в нашу идею. Помогли доработать. Результат супер!</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Павел П.</p>
                                        <p className={styles.comment}>23.08.2024</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Обращался неоднократно! Ни разу не подвели! Ребята грамотные, профессионалы! Заказывал нестандартные шаблоны! Оснастку! Пластины из нержавейки! Сами занимаемся производством окон, имеем оборудование. Если проблемы с оснасткой , знаем где заказать и решить проблему! Удачи Вам! Спасибо! Рекомендую!</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Филипп С.</p>
                                        <p className={styles.comment}>27.07.2024</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Обратился в первый раз и, думаю, буду работать с ними дальше. Не часто встречаются люди, которые знают, что делают и реально помогают сделать качественно и в срок. Рекомендую работать с ними!</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Елизавета М.</p>
                                        <p className={styles.comment}>27.03.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Очень довольны качеством работы данной компании. Заказывали 3д печать деталей из пластика. Нам помогли подобрать цвета под наше ТЗ, к одному из объектов подобрали цвет по RAL и покрасили в него. Порадовало, что есть полный цикл производства от помощи в ТЗ до обработки моделей после печати и покраски. Обязательно будем еще обращаться с новыми проектами!</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>           
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Антон Кузнецов</p>
                                        <p className={styles.comment}>18.01.2023</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Ребята молодцы, делают 3д модели быстро и качественно, любой сложности из любого пластика. Дешевле,по соотношению цена-качество не нашел. Теперь постоянно заказываем у них модели.</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>        
                            <SwiperSlide>
                                <div className={styles.cell}>
                                    <div className={styles.item}>
                                        <p className={styles.subtitle}>Инесса Иванова</p>
                                        <p className={styles.comment}>15.12.2022</p>
                                        <img className={styles.image} src={img} alt = 'звездочки'/>
                                        <p className={styles.comment}>Комментарий</p>
                                        <p className={styles.text}>Заказывала 3Д печать, сделали достаточно сложную модель, первые кто смог такое напечатать, всё очень быстро и аккуратно, так же помогли составить саму модель.</p>                              
                                    </div>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </motion.div>
        </motion.section>
    )
}

export default Feedback