import React from 'react'
import { motion } from "framer-motion"
import styles from './Questions.module.scss'
import Hint from '../../components/Hint/Hint'

const titleAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const boxAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}


const Questions = () => {

    return (
        <motion.section
            id='Voprosy'
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
                    <div className={styles.box}>
                        <div className={styles.container}>
                            <motion.h2 className={styles.title} variants={titleAnimation}>ВОПРОС-ОТВЕТ</motion.h2>
                            <motion.ul className={styles.list} variants={boxAnimation}>
                                <Hint
                                    question='Какие способы оплаты и условия доставки вы предлагаете?'
                                >
                                <p className={styles.text}><span>Оплата:</span> Мы работаем по системе предоплаты в размере 30% от общей стоимости заказа. Оставшиеся 70% оплачиваются после завершения работ и перед отправкой готового изделия. Такой подход обеспечивает взаимное доверие и гарантирует серьёзность намерений обеих сторон.</p>
                                <p className={styles.text}><span>Доставка:</span> Доставка готовых изделий входит в стоимость заказа. Мы организуем доставку удобным для вас способом, будь то курьерская служба, транспортная компания или самовывоз. Все детали доставки заранее согласовываются с заказчиком, чтобы обеспечить своевременное и безопасное получение продукции.</p>    
                                </Hint>
                                <Hint
                                    question='Каковы сроки выполнения заказа?'
                                >
                                <p className={styles.text}>Сроки выполнения заказа обычно составляют <span>от одной до двух недель,</span> но могут варьироваться в зависимости от сложности проекта, объёма работ и текущей загруженности производства. Мы всегда стремимся выполнить работу в оптимальные сроки без ущерба для качества. Точные сроки обсуждаются индивидуально при согласовании заказа, с учётом всех ваших пожеланий и требований.</p>
                                </Hint>
                                <Hint
                                    question='Есть ли дополнительные платежи?'
                                >
                                <p className={styles.text}>Цена, согласованная при оформлении заказа, является фиксированной. Дополнительные платежи могут возникнуть только в случае, если увеличивается объём работы или вносятся изменения, которые не были учтены в первоначальных договорённостях. В таких ситуациях мы обязательно <span>предварительно</span> обсуждаем с вами все изменения и связанные с ними расходы, чтобы избежать недоразумений.</p>
                                </Hint>
                                <Hint
                                    question='Могу ли я следить за процессом разработки моих деталей?'
                                >
                                <p className={styles.text}>Да, конечно! Мы ценим прозрачность в работе и считаем, что участие заказчика в процессе разработки способствует получению наилучшего результата. На каждом этапе мы предоставляем <span>фото или видеоотчёты,</span> показывающие прогресс работы. Перед отправкой деталей или материалов мы также делаем подробные фотографии для вашего согласования. Это позволяет вам быть в курсе всех деталей и, при необходимости, вносить корректировки своевременно.</p>
                                </Hint>
                                <Hint
                                    question='Сколько стоит разработка изделий?'
                                >
                                <p className={styles.text}>Стоимость разработки изделий <span>сильно варьируется</span> и зависит от множества факторов:</p>
                                <p className={styles.text}><span>&#183; Сложность проекта: </span> Чем сложнее изделие в техническом плане, тем больше ресурсов требуется для его разработки.</p>
                                <p className={styles.text}><span>&#183; Используемые материалы: </span> Цена материалов может существенно влиять на общую стоимость.</p>
                                <p className={styles.text}><span>&#183; Технологии производства: </span> Различные методы изготовления имеют разную себестоимость.</p>
                                <p className={styles.text}><span>&#183; Объём работ: </span> Массовое производство обычно дешевле в расчёте на единицу продукции, чем единичные заказы.</p>
                                <p className={styles.text}>Чтобы предоставить вам точную стоимость, нам необходимо ознакомиться с вашим <span>техническим заданием,</span> чертежами или образцами. После детального анализа мы подготовим для вас индивидуальное <span>коммерческое предложение</span> с указанием всех условий.</p>
                                </Hint>
                                <Hint
                                    question='Нужно ли встречаться лично для обсуждения деталей проекта?'
                                >
                                <p className={styles.text}>Личная встреча не является обязательной, но мы всегда готовы встретиться с вами для более детального обсуждения проекта. Это особенно полезно при сложных или уникальных задачах, где требуется непосредственное взаимодействие. Мы можем организовать встречу в нашем офисе, приехать к вам или выбрать любое другое удобное место. Если личная встреча затруднительна, мы доступны для общения по телефону, электронной почте, а также через различные мессенджеры (WhatsApp, Telegram и др.).</p>
                                </Hint>
                                <Hint
                                    question='У меня уже есть деталь. Можете ли вы внести в неё изменения?'
                                >
                                <p className={styles.text}>Да, мы можем внести изменения в уже существующую деталь. Наши специалисты способны выполнить доработку, модификацию или улучшение детали в соответствии с вашими пожеланиями. Стоимость и сроки выполнения таких работ зависят от:</p>
                                <p className={styles.text}><span>&#183; Типа изменений:  </span> Косметические правки, функциональные улучшения или структурные изменения.</p>
                                <p className={styles.text}><span>&#183; Сложности работ:  </span> Технологические процессы, необходимые для реализации изменений.</p>
                                <p className={styles.text}><span>&#183; Материалов и технологий, используемых в исходной детали.</span></p>
                                <p className={styles.text}>Пожалуйста, предоставьте нам деталь, её чертежи или 3D-модели, чтобы мы могли оценить объём работ и предложить оптимальное решение.</p>
                                </Hint>
                                <Hint
                                    question='Как мы можем ускорить процесс выполнения услуги?'
                                >
                                <p className={styles.text}>Для ускорения процесса выполнения работ рекомендуем:</p>
                                <p className={styles.text}><span>&#183; Предоставить полную информацию заранее: </span> Подготовьте все необходимые материалы, включая <span>технические задания, чертежи, 3D-модели</span> (предпочтительно в формате STEP) и любые другие документы, которые могут быть полезны.</p>
                                <p className={styles.text}><span>&#183; Оперативно реагировать на запросы: </span> Быстрая коммуникация позволяет нам своевременно получать ответы на возникшие вопросы и продолжать работу без задержек.</p>
                                <p className={styles.text}><span>&#183; Сообщить о срочности проекта: </span> Если вам необходимо выполнить работу в сжатые сроки, уведомьте нас об этом при оформлении заказа. Мы постараемся скорректировать график работ и, по возможности, ускорить процесс. Обратите внимание, что в случае срочного выполнения может быть предусмотрена <span>дополнительная наценка за срочность.</span></p>
                                </Hint>
                                <Hint
                                    question='Сколько стоит разработка деталей с нуля?'
                                >
                                <p className={styles.text}>Разработка деталей "с нуля" — это комплексный процесс, стоимость которого зависит от:</p>
                                <p className={styles.text}><span>&#183; Сложности и новизны проекта: </span></p>
                                <p className={styles.text}>&#8195;<span>&#183; Концептуальная разработка: </span>Создание оригинального дизайна и функционала.</p>
                                <p className={styles.text}>&#8195;<span>&#183; Инженерные расчёты:  </span>Необходимость в проведении прочностных, тепловых и других анализов.</p>
                                <p className={styles.text}><span>&#183; Этапы производства: </span></p>
                                <p className={styles.text}>&#8195;<span>&#183; 3D-моделирование: </span>Создание подробной цифровой модели изделия.</p>
                                <p className={styles.text}>&#8195;<span>&#183; Прототипирование:  </span>Изготовление прототипа на <span>3D-принтере</span> для проверки дизайна и функциональности.</p>
                                <p className={styles.text}>&#8195;<span>&#183; Тестирование и доработка: </span>Выявление и исправление возможных недостатков.</p>
                                <p className={styles.text}>&#8195;<span>&#183; Серийное производство:  </span>Изготовление детали на станках с числовым программным управлением <span>(ЧПУ)</span> или другими методами.</p>
                                <p className={styles.text}><span>&#183; Используемые материалы и технологии: </span></p>
                                <p className={styles.text}>&#8195;<span>&#183; </span>Выбор оптимального материала для ваших целей (металл, пластик, композиты и т.д.).</p>
                                <p className={styles.text}>&#8195;<span>&#183; </span>Технологические процессы (фрезеровка, токарная обработка и др.).</p>
                                </Hint>
                                <Hint
                                    question='Какие технологии и оборудование вы используете?'
                                >
                                <p className={styles.text}><span>&#183; </span> В нашем распоряжении современное оборудование, включая станки с числовым программным управлением (ЧПУ), 3D-принтеры, лазерные и плазменные резаки, а также другие передовые инструменты. Мы постоянно обновляем парк оборудования и внедряем новые технологии, чтобы обеспечивать высокое качество и эффективность производства.</p>
                                <p className={styles.text}><span>&#183; </span> Благодаря тщательному прототипированию и согласованию на каждом этапе, мы минимизируем риск брака и гарантируем, что конечное изделие будет соответствовать вашим ожиданиям. Чтобы определить точную стоимость, пожалуйста, предоставьте нам максимально подробное техническое задание или опишите ваши идеи и требования. Мы с удовольствием подготовим для вас индивидуальное коммерческое предложение с указанием стоимости, сроков и этапов выполнения работ.</p>
                                </Hint>    
                            </motion.ul>
                            <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79299473078' aria-label='связаться вацап'>
                                <span>Задать свой вопрос в Whatsapp</span>
                                <svg  xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.92327 0.277344C4.59796 0.277344 0.2771 4.5982 0.2771 9.92351C0.2771 12.0559 0.967314 14.0199 2.13451 15.6192L0.905588 19.2554L4.73824 18.0602C6.23651 19.0141 8.01536 19.5697 9.92327 19.5697C15.2486 19.5697 19.5694 15.2488 19.5694 9.92351C19.5694 4.5982 15.2486 0.277344 9.92327 0.277344ZM14.3395 15.0973C12.5887 15.7988 10.5013 15.1198 8.21737 13.3858C5.93349 11.6519 4.42961 9.21085 4.3286 7.39834C4.2276 5.58582 5.70342 4.67115 6.50587 4.67676C7.30831 4.68237 8.18932 7.24683 8.20054 7.63963C8.21176 8.03805 7.21853 8.7451 7.17925 9.08179C7.13997 9.41848 8.53162 11.0795 9.12083 11.5284C9.70442 11.9717 10.9109 12.8247 11.5338 12.7461C12.151 12.6619 12.5607 11.4218 12.9535 11.3432C13.3463 11.2647 15.4899 12.3308 15.7031 12.6058C15.9164 12.8864 15.8939 14.4801 14.3395 15.0973Z"></path>
                                </svg>
                            </a>
                        </div>
                    </div>
        </motion.section>
        
    )
}

export default Questions