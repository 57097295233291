import React, {useState, useEffect, useCallback} from 'react'
import { motion } from "framer-motion"
import styles from './Portfolio.module.scss'
import { v4 as uuidv4 } from 'uuid'
import ScreenSize from '../../hooks/ScreenSize'
import CardImage  from '../CardImage/CardImage'
import { arrPortfolio } from '../../utils/data'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 500,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}


const Portfolio = ({isImageOpen}) => {

    const width = ScreenSize()
    const [moviesTotal, setMoviesTotal] = useState(0)
    const [addMovies, setAddMovies] = useState(0)


    useEffect(() => {
        const getCards = () => {
            if (width >= 980) {
                setMoviesTotal(6);
                setAddMovies(2);
                } else if (width < 980 && width >= 500) {
                    setMoviesTotal(4);
                    setAddMovies(4);
                        } else if (width <= 500) {
                            setMoviesTotal(3);
                            setAddMovies(5);
                            }
        }
        getCards();
    }, [width])

    const addCards = useCallback(() => {
        setMoviesTotal(moviesTotal + addMovies);
    }, [moviesTotal, addMovies])

    return (
        <motion.section 
            className={styles.main}
            id='Photo' 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <div className={styles.box}>
                <motion.h2 className={styles.title} variants={titleAnimation}>ПРИМЕРЫ <span> НАШИХ РАБОТ</span></motion.h2>
                <motion.ul className={styles.list} variants={boxAnimation}>
                    {arrPortfolio.map((item, index) =>{
                        const keyUid = uuidv4()
                        if (index + 1 <= moviesTotal) {
                            return(
                                <CardImage
                                    key={keyUid}
                                    item={item}
                                    index={index}
                                    isImageOpen={isImageOpen}
                                />
                            ) 
                        }else {
                            return ''
                        }
                    })}
                </motion.ul>
                {moviesTotal < arrPortfolio.length && <button  className={styles.button} onClick={addCards}>Ещё работы <span className={styles.glare}></span></button>}
            </div>
        </motion.section>
    )
}

export default Portfolio