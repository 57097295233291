import React from 'react'
import header from './HeaderContact.module.scss'

const HeaderContact = ({isOpen}) => {

    return (
        <div className={header.contacts}>
            <div className={header.call}>
                <div className={header.call_pulse}></div>
                <p className={header.call_text}>Звоните, ПН-ВС 09:00-22:00</p>
            </div>
            <div className={header.box_phone}>
                <a className={header.phone}  href="tel:+79299473078" target='_blank' rel='noopener noreferrer'>+7 929 947 30 78</a>
            </div>
            <p className={header.text} onClick={isOpen}>заказать обратный звонок</p>
        </div>
    );
};

export default HeaderContact